<template>
  <div class="bill">
    <panel-title icon="el-icon-s-order" title="我的订单" />

    <panel-content>
      <div slot="filter" class="filter">
        <div class="top-filter">
          <div class="left">
            <span class="active">全部订单</span>
            <span>未开始</span>
            <span>进行中</span>
            <span>待确认合同</span>
            <span>已完成</span>
            <span>回收站</span>
          </div>
          <div class="right">
            <span
              class="more-filter-btn"
              @click="showMoreFilter = !showMoreFilter"
              >高级筛选

              <i
                :class="[
                  showMoreFilter ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
                ]"
              ></i>
            </span>
          </div>
        </div>
        <div class="bottom-filter" v-show="showMoreFilter">
          <span>时间：</span>
          <el-date-picker
            style="margin: 0 10px"
            size="medium"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-input
            size="medium"
            style="width: 200px; margin: 0 10px"
            placeholder="请输入订单编号或名称"
          />
          <el-button type="primary" size="medium">立即查询</el-button>
        </div>
      </div>
      <div class="table-wrap" slot="content">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column label="选择时间"> </el-table-column>

          <el-table-column prop="name" label="订单类型/订单状态" width="120">
          </el-table-column>

          <el-table-column prop="name" label="订单金额" width="120">
          </el-table-column>

          <el-table-column prop="name" label="所需时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作" width="120">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <!-- <div class="pagination-wrap">
          <ori-pagination />
        </div> -->
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },

  data() {
    return {
      showMoreFilter: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.bill {
  .top-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      span {
        cursor: pointer;
        display: inline-block;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid #ccc;

        font-size: 14px;

        line-height: 17px;

        &:hover,
        &:active,
        &.active {
          color: red;
        }
      }

      span:last-child {
        border-right: none;
      }
    }

    .right {
      .more-filter-btn {
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        color: red;
        margin-left: 10px;
      }
    }
  }

  .bottom-filter {
    margin-top: 15px;
  }

  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>